<script setup>
    import { onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import TheHeader from './components/ui/TheHeader.vue'
    import TheFooter from './components/ui/TheFooter.vue'
    import NavMenu from './components/ui/NavMenu.vue'

    const router = useRouter();

    onMounted(() => {
        // Intercept links to use vue router on internal links and add GA events to external and files (downloads and external clicks are handled by GA enhanced events so we haven't added them here. Left in for info)
        window.addEventListener('click', event => {
            // ensure we use the link, in case the click has been received by a subelement
            let { target } = event;
            while (target && target.tagName !== 'A') target = target.parentNode;
            // handle only links that do not reference external resources
            if (target && target.matches("a:not([href*='://']):not([href*='mailto:']):not([href*='tel:']):not([href*='/_resources/'])") && target.href) {
                // some sanity checks taken from vue-router:
                // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
                const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
                // don't handle with control keys
                if (metaKey || altKey || ctrlKey || shiftKey) return
                // don't handle when preventDefault called
                if (defaultPrevented) return
                // don't handle right clicks
                if (button !== undefined && button !== 0) return
                // don't handle if `target="_blank"`
                if (target && target.getAttribute) {
                    const linkTarget = target.getAttribute('target')
                    if (/\b_blank\b/i.test(linkTarget)) return
                }
                // don't handle same page links/anchors
                const url = new URL(target.href);
                if (window.location.pathname !== url.pathname && event.preventDefault) {
                    event.preventDefault();
                    router.push(url.pathname + url.search + url.hash);
                }
            }
        })
    });

</script>

<template>
    <the-header />
    <nav-menu />
    <router-view v-slot="{ Component, route }">
        <transition name="fade-route">
            <div :key="route.path">
                <component :is="Component" />
            </div>
        </transition>
    </router-view>
    <the-footer />
</template>

<style>
    /*#region Variables
    --------------------------  */

    :root {
        /*Colours*/
        --black: #2C2B29;
        --black-90: #2C2B29E6;
        --black-80: #2C2B29CC;
        --grey1: #B7B3AD;
        --grey2: #F5F5F5;
        --grey3: #D3CCC2;
        --grey4: #83807C;
        --grey5: #707070;
        --grey6: #e3e0db;
        --white: #fff;
        --white-70: #ffffffb3;
        --bronze: #AC593B;
        --red: #83807C;
        /*svg colours*/
        --svg-black: url(#black);
        --svg-grey-1: url(#grey-1);
        --svg-grey-4: url(#grey-4);
        --svg-dark-grey: url(#dark-grey);
        --svg-bronze: url(#bronze);
        /*Fonts */
        --font-text: noto-sans, sans-serif;
    }

    /*#endregion */

    /*#region Classes
    --------------------------  */
    .centre {
        text-align: center;
    }

    .inner {
        max-width: 71.875rem;
        margin: 0 auto;
    }

    .inner-padded {
        padding: 0 1.875rem;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    /* Flex */
    .flex {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -ms-flex-direction: row;
        -webkit-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: stretch;
    }

        .flex > * {
            -ms-flex: 0 0 100%;
            -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
        }

        .flex.column {
            -ms-flex-direction: column;
            -webkit-flex-direction: column;
            flex-direction: column;
        }

            .flex.column > * {
                -ms-flex: 0 0 auto;
                -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
            }


    /* Link Buttons */
    button.button, a.button, .button > a {
        display: block;
        padding: 0.8em 2em;
        border: solid 1px var(--black);
        color: var(--black);
        background-color: transparent;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        font-weight: 400;
        cursor: pointer;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -moz-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
    }
        button.button.reverse, a.button.reverse, .button.reverse > a {
            border: solid 1px var(--white);
            color: var(--white);
        }

            button.button:hover, a.button:hover, .button > a:hover {
                background-color: var(--black);
                color: var(--white);
            }
            button.button.reverse:hover, a.button.reverse:hover, .button.reverse > a:hover {
                background-color: var(--white);
                color: var(--black);
            }

        button.inline-button {
            font-size: 1rem;
            display: inline-block;
            padding: 0;
            margin: 0;
            border: 0;
            background-color: transparent;
            cursor: pointer;
        }

    /* Download Buttons */
    a.button.download.flex {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        font-size: 0.9em;
        text-transform: none;
        font-weight: 400;
        margin-bottom: 1.25rem;
    }

        a.button.download.flex > * {
            flex-basis: auto;
        }

    a.button.download:after {
        content: "";
        background: transparent url(@/assets/icons/arrow-left.svg) no-repeat center center;
        display: block;
        width: 0.8125rem;
        height: 0.8125rem;
        margin-left: auto;
        margin-top: 0.5em;
        -ms-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        -moz-filter: var(--svg-black);
        -webkit-filter: var(--svg-black);
        filter: var(--svg-black);
        -moz-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        -webkit-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out;
    }

    a.button.download:hover:after {
        -moz-filter: var(--svg-white);
        -webkit-filter: var(--svg-white);
        filter: var(--svg-white);
    }

    /* Transitions */
    .fade-enter-active {
        transition: opacity 0.5s ease;
        transition-delay: 0.2s;
    }
    .fade-leave-active {
        transition: opacity 0.5s ease;
        transition-delay: 0.1s;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    /* List transitions. Use max height on entering elements to stop jumping */
    .fade-list-enter-active {
        margin: 0;
        transition: opacity 0.5s ease, max-height 0s ease;
        transition-delay: 0.5s, 0.5s;
    }
    .fade-list-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-list-leave-to {
        opacity: 0;
    }
    .fade-list-enter-from {
        max-height: 0;
        opacity: 0;
    }
    .fade-list-enter-to {
        max-height: 50em;
    }

    /* Routes */
    .fade-route-enter-active {
        transition: opacity 0.2s ease;
        transition-delay: 0.5s;
    }
    .fade-route-leave-active {
        transition: opacity 0.1s ease;
    }

    .fade-route-enter-from,
    .fade-route-leave-to {
        opacity: 0;
    }

    @media screen and (min-width: 600px) {
        .container {
            max-width: 71.125em;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @media screen and (min-width: 900px) {
        .narrow {
            max-width: 45.25em;
            margin-left: auto;
            margin-right: auto;
        }
    }

    /*#endregion */

    /*#region Typography
    --------------------------  */

    body {
        font-family: var(--font-text);
        font-size: 1em;
        font-weight: 400;
        line-height: 1.5em;
        text-align: left;
    }

    h1, h2, h3, h4 {
        margin: 0 0 1rem 0;
    }

    h1 {
        font-size: 1.2em;
        font-weight: 600;
        text-transform: uppercase;
    }

    h2 {
        font-size: 1.125em;
        font-weight: 600;
    }

    h3 {
        font-size: 1.125em;
        font-weight: 400;
    }

    h4 {
        font-size: 1.125em;
        font-weight: 400;
    }

    p {
        font-size: 0.9em;
        margin: 0 0 1em 0;
    }

    li, dt, dd {
        font-size: 1em;
        margin: 0 0 1em 0;
    }
        li li {
            font-size: 1em;
        }

    strong {
        font-weight: 700;
    }

    input, select, textarea, label {
        font-family: var(--font-text);
        font-size: 1em;
    }

    button {
        font-family: var(--font-text);
        font-weight: 400;
        font-size: 0.85em;
        margin: 0;
    }

    img {
        max-width: 100%;
    }

    picture img {
        display: block;
    }

    /*#endregion */

    /*#region  Main Structure
    -------------------------------------  */

    html, body {
        height: 100%;
    }

    html {
        font-size: 1em;
        scroll-behavior: smooth;
        min-width: 22.5em;
    }

    article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
        display: block;
    }

    picture {
        display: inline-block;
    }

    body {
        background-color: #fff;
        color: var(--black);
        width: 100%;
        padding: 0;
        margin: 0;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    main {
        margin: 0;
    }

        main a {
            color: var(--black);
            font-weight: 600;
            text-decoration: underline 0.1em transparent;
            text-underline-offset: 0.225em;
            -moz-transition: all .2s linear;
            -o-transition: all .2s linear;
            -webkit-transition: all .2s linear;
            transition: all .2s linear;
        }

            main a:hover {
                color: var(--grey4);
                text-decoration-color: var(--grey4);
                text-underline-offset: 0.2em;
            }

    @media screen and (min-width: 900px) {
        main h1 {
            text-align: center;
        }
    }

    /*#endregion */

    /*#region  Loading animation
    -------------------------------------  */

    .loader {
        display: block;
        font-size: 10px;
        margin: 0 auto;
        width: 3.5em;
        height: 3.5em;
        border-radius: 50%;
        opacity: 0.5;
        background: var(--white);
        background: -moz-linear-gradient(left, var(--black) 10%, var(--white) 42%);
        background: -webkit-linear-gradient(left, var(--black) 10%, var(--white) 42%);
        background: -o-linear-gradient(left, var(--black) 10%, var(--white) 42%);
        background: -ms-linear-gradient(left, var(--black) 10%, var(--white) 42%);
        background: linear-gradient(to right, var(--black) 10%, var(--white) 42%);
        -webkit-animation: load3 1.2s infinite linear;
        animation: load3 1.2s infinite linear;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }

        .loader:before {
            width: 50%;
            height: 50%;
            background: var(--black);
            border-radius: 100% 0 0 0;
            position: absolute;
            top: 0;
            left: 0;
            content: '';
        }

        .loader:after {
            background: var(--white);
            width: 75%;
            height: 75%;
            border-radius: 50%;
            content: '';
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }

    @-webkit-keyframes load3 {
        0% {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes load3 {
        0% {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    /*#endregion */

</style>
