/* DOM manipulation */
var classList = {
    add: function (o, c) {
        if (typeof o.className === "object") {
            return false;
        }
        if (o.classList !== undefined) {
            o.classList.add(c);
        }
        else {
            if (!o.className.contains(" " + c) && !o.className.contains(c + " ") && o.className !== c) {
                if (o.className !== "") {
                    o.className = o.className + " " + c;
                }
                else {
                    o.className = c;
                }
            }
        }
    },
    remove: function (o, c) {
        if (typeof (o.className) === "object") {
            return false;
        }
        if (o.classList !== undefined) {
            o.classList.remove(c);
        }
        else {
            if (o.className.contains(" " + c)) {
                o.className = o.className.replace(" " + c, "");
            }
            else if (o.className.contains(c + " ")) {
                o.className = o.className.replace(c + " ", "");
            }
            else if (o.className === c) {
                o.className = "";
            }
        }
    },
    toggle: function (o, c) {
        if (typeof o.className === "object") {
            return false;
        }
        if (o.classList !== undefined) {
            o.classList.toggle(c);
        }
        else {
            if (o.className.contains(" " + c) || o.className.contains(c + " ") || o.className === c) {
                this.classList.remove(o, c);
            }
            else {
                this.classList.add(o, c);
            }
        }
    },
    contains: function (o, c) {
        if (typeof o.className === "object") {
            return false;
        }
        return this.classContains(o, c);
    }
};

export const bsdom = {
    obj: null,

    get: function (o) {
        var obj = Object.create(this);
        if (typeof o === "object") {
            obj.obj = o;
        }
        else {
            obj.obj = document.getElementById(o) ? document.getElementById(o) : document.getElementsByName(o)[0];
        }
        return obj;
    },
    create: function (n) {
        var obj = Object.create(this);
        obj.obj = document.createElement(n);
        return obj;
    },
    append: function (o) {
        var obj = typeof o === "object" ? bsdom.get(o) : bsdom.create(o);
        this.obj.appendChild(obj.obj);
        return obj;
    },
    remove: function (o) {
        var obj = null;
        if (typeof o === "object") {
            obj = bsdom.get(o);
            this.el.removeChild(obj.obj);
        }
        return obj;
    },
    insert: function (o, r, p) {
        var obj = typeof o === "object" ? bsdom.get(o) : bsdom.create(o);

        if (typeof r === "object") {
            var robj = bsdom.get(r);
            if (p === "a") {
                robj.obj.nextSibling ? this.obj.insertBefore(obj.obj, robj.obj.nextSibling) : this.append(obj.obj);
            }
            else {
                this.obj.insertBefore(obj.obj, robj.obj);
            }
        }
        else {
            this.obj.childNodes.length >= r ? this.obj.insertBefore(obj.obj, this.obj.childNodes[r]) : this.append(obj.obj);
        }

        return obj;
    },
    text: function (t) {
        this.obj.appendChild(document.createTextNode(t));
        return this;
    },
    html: function (t) {
        this.obj.innerHTML = t;
        return this;
    },
    gettext: function () {
        return this.obj.firstChild.nodeValue;
    },
    getvalue: function () {
        return this.obj.value;
    },
    attr: function (k, v) {
        if (v === null) {
            this.obj.removeAttribute(k);
        }
        else {
            this.obj.setAttribute(k, v);
        }
        return this;
    },
    getattr: function (k) {
        if (this.obj.getAttribute(k) !== null) {
            return this.obj.getAttribute(k);
        }
        else {
            return "";
        }
    },
    cssadd: function (c) {
        classList.add(this.obj, c);
        return this;
    },
    cssremove: function (c) {
        classList.remove(this.obj, c);
        return this;
    },
    csstoggle: function (c) {
        classList.toggle(this.obj, c);
        return this;
    },
    csscontains: function (c) {
        return classList.contains(this.obj, c);
    },
    event: function (e, f, p, p2, p3) {
        if (p3) {
            this.obj["e" + e] = function (e) { f(p, p2, p3, e); };
        }
        else if (p2) {
            this.obj["e" + e] = function (e) { f(p, p2, e); };
        }
        else if (p) {
            this.obj["e" + e] = function (e) { f(p, e); };
        }
        else {
            this.obj["e" + e] = f;
        }
        this.obj.addEventListener(e, this.obj["e" + e], false);
        return this;
    },
    removeevent: function (e) {
        this.obj.removeEventListener(e, this.obj["e" + e], false);
        this.obj["e" + e] = null;
        return this;
    },
    parent: function (t) {
        if (t) {
            var pn = this.parent();
            while (pn.obj.tagName.toLowerCase() !== t.toLowerCase() && pn.obj.tagName.toLowerCase() !== "body") {
                pn = pn.parent();
            }
            return pn;
        }
        else {
            return bsdom.get(this.obj.parentNode);
        }
    },
    child: function (t, i) {
        return bsdom.get(this.obj.getElementsByTagName(t)[i ? i === -1 ? this.obj.getElementsByTagName(t).length - 1 : i : 0]);
    },
    children: function (t, s) {
        var r = [];
        if (s === "class") {
            for (var i = 0; i < this.obj.getElementsByClassName(t).length; i++) {
                r.push(bsdom.get(this.obj.getElementsByClassName(t)[i]));
            }
        }
        else {
            for (var j = 0; j < this.obj.childNodes.length; j++) {
                if (this.obj.childNodes[j].tagName !== undefined && (t === undefined || this.obj.childNodes[j].tagName.toLowerCase() === t.toLowerCase())) r.push(bsdom.get(this.obj.childNodes[j]));
            }
        }
        return r;
    },
    selectall: function (t) {
        var r = [];
        for (var i = 0; i < this.obj.getElementsByTagName(t).length; i++) {
            r.push(bsdom.get(this.obj.getElementsByTagName(t)[i]));
        }
        return r;
    },
    move: function (o) {
        o.obj.appendChild(this.obj);
    },
    clone: function (d) {
        var obj = Object.create(this);
        obj.obj = this.obj.cloneNode(d);
        return obj;
    },
    clear: function () {
        while (this.obj.childNodes.length > 0) {
            this.obj.removeChild(this.obj.childNodes[0]);
        }
        return this;
    },
    dispose: function () {
        var p = this.obj.parentNode;
        p.removeChild(this.obj);
        this.obj = null;
        return p;
    }
}

