const isNewWindow = function (url) {
    if (url.indexOf("#") > -1) url = url.split("#")[0];
    if (url.startsWith("http") || [".pdf", ".doc", ".docx", ".xls", ".xlsx"].some(x => url.endsWith(x))) return true;
    return false;
};

const linkTarget = function (url) {
    return isNewWindow(url) ? '_blank' : '_self'
}

const linkHref = function (url, type) {
    const types = {
        m: "mailto:",
        t: "tel:"
    }
    return `${types[type]}${url.replace(/\s/g, '')}`;
}

export { linkTarget, isNewWindow, linkHref }
