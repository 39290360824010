<script setup>
    import { defineProps, defineEmits, computed } from 'vue'
    import { isNewWindow } from '@/composables/links.js'

    const props = defineProps({
        entityId: {
            type: Number,
            required: true
        },
        entityType: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        url: {
            type: String,
            required: true
        },
        routes: {
            type: Array,
            required: false
        },
        open: {
            type: Boolean,
            default: false
        }
    });

    // Emits
    const emit = defineEmits(['toggleOpen']);

    // Computed
    const subRoutes = computed(function () {
        return props.routes.filter(r => r.entityType !== "products")
    });

    const className = computed(function () {
        return `${props.title.replace(/&/g, '').replace(/\s\s/g, '-').replace(/\s/g, '-').toLowerCase()}${(props.open ? " sub-menu-open" : "")}`;
    });
    const isSubMenu = computed(function () {
        return subRoutes.value && subRoutes.value.length > 0;
    });

    const toggleOpen = function () {
        // emit value
        emit('toggleOpen', props.entityId);
    }

</script>

<template>
    <li 
        class="nav-route" 
        :class="className">
        
        <router-link 
                     v-if="!isSubMenu && !isNewWindow(url)"
                     :to="url">
        {{ title }}</router-link>

        <a v-if="!isSubMenu && isNewWindow(url)"
           :href="url"
           target="_blank">
            {{ title }}
        </a>

        <button type="button"
                v-if="isSubMenu"
                @click="toggleOpen">
            {{ title }}
        </button>

        <ul v-if="subRoutes && subRoutes.length > 0"
            class="sub-menu flex">
            <nav-menu-item v-bind="route"
                           v-for="(route, index) in subRoutes"
                           :key="index"></nav-menu-item>
        </ul>

    </li>
</template>

<style>
    li.nav-route {
        margin-bottom: 0;
    }

        li.nav-route a, li.nav-route button {
            display: block;
            padding: 1rem 1rem;
            font-size: 1rem;
            color: var(--grey3);
            text-decoration: none;
            text-transform: uppercase;
            width: 100%;
            position: relative;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
        }

        li.nav-route ul.sub-menu li.nav-route a, li.nav-route ul.sub-menu li.nav-route button {
            text-transform: none;
        }

    li.nav-route button {
        border: none;
        cursor: pointer;
        text-align: left;
        background: transparent;
        font-weight: 600;
    }

        li.nav-route button:after {
            content: "";
            background: transparent url(@/assets/icons/chevron.svg) no-repeat center center;
            display: block;
            width: 1rem;
            height: 1rem;
            position: absolute;
            top: calc(50% - 0.5rem);
            right: 1.5rem;
            -moz-transition: all .4s ease;
            -o-transition: all .4s ease;
            -webkit-transition: all .4s ease;
            transition: all .4s ease;
            -ms-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            -moz-filter: var(--svg-dark-grey);
            -webkit-filter: var(--svg-dark-grey);
            filter: var(--svg-dark-grey);
        }

        li.nav-route ul.sub-menu {
            max-height: 0;
            overflow: hidden;
            margin: 0;
            padding: 0;
            opacity: 0;
            background-color: var(--grey4);
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            list-style-type: none;
            -moz-transition: max-height .4s ease-in-out, opacity .4s ease-out;
            -o-transition: max-height .4s ease-in-out, opacity .4s ease-out;
            -webkit-transition: max-height .4s ease-in-out, opacity .4s ease-out;
            transition: max-height .4s ease-in-out, opacity .4s ease-out;
        }


    /*Open*/
    li.sub-menu-open ul.sub-menu {
        max-height: 60em;
        opacity: 1;
    }
    li.sub-menu-open > button {
        font-weight: 800;
    }
        li.sub-menu-open > button:after {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

    ul.sub-menu li.nav-route a, ul.sub-menu li.nav-route button {
        color: var(--white);
    }


    @media screen and (min-width: 600px) {

        li.nav-route {
            width: 18em;
        }

            li.nav-route > a:hover, li.nav-route > button:hover, li.nav-route.sub-menu-open > a, li.nav-route.sub-menu-open > button {
                background-color: var(--grey4);
                color: var(--white);
            }

            li.nav-route button:after, li.sub-menu-open > button:after {
                -ms-transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                -o-transform: rotate(90deg);
                -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
            }

            li.nav-route ul.sub-menu {
                position: absolute;
                left: 18em;
                top: 0;
                width: calc(100vw - 18em - 18px);
                background-color: transparent;
            }

                li.nav-route ul.sub-menu li.nav-route {
                    flex-basis: 50%;
                }

            li.nav-route:hover ul.sub-menu {
                max-height: 60em;
                opacity: 1;
            }

            li.sub-menu-open ~ li.nav-route:hover ul.sub-menu,
            li.nav-route:has(~ .sub-menu-open):hover ul.sub-menu {
                max-height: 0;
                opacity: 0;
            }
    }
    
    @media screen and (min-width: 900px) {

        li.nav-route {
            width: 20em;
        }

            li.nav-route a, li.nav-route button {
                padding: 1rem 2rem;
            }

            li.nav-route ul.sub-menu {
                left: 20em;
                width: calc(100vw - 20em - 18px);
            }

            li.nav-route ul.sub-menu {
                width: 30em;
            }

    }

</style>