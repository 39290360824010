<script setup>
    import { defineEmits, watch, ref } from 'vue'
    import { useRoute } from 'vue-router'
    import { bsdom } from '@/composables/bsdom.js'

    const route = useRoute();

    // Emits
    const emit = defineEmits(['nav-toggle']);

    const open = ref(false);

    const toggleNav = function () {
        // toggle open
        open.value = !open.value;
        // emit value
        emit('nav-toggle', open.value)
        // Set body class
        bsdom.get(document.body).csstoggle("nav-open");
    }

    // Close if route changes
    watch(route,
        () => {
            if (open.value) toggleNav();
        }
    );

</script>

<template>
    <button type="button"
            class="mainnav"
            :class="{ open: open }"
            @click="toggleNav">
        <span>Menu</span>
        <span class="navicon">
            <span></span>
        </span>
    </button>
</template>

<style>
    button.mainnav {
        font-size: 0.75em;
        text-transform: uppercase;
        background-color: transparent;
        border: 0;
        color: var(--black);
        opacity: 1;
        cursor: pointer;
        padding: 0;
        margin: 0;
        -ms-flex: 0 0 35px;
        -webkit-flex: 0 0 35px;
        flex: 0 0 35px;
        -moz-transition: opacity linear .2s;
        -o-transition: opacity linear .2s;
        -webkit-transition: opacity linear .2s;
        transition: opacity linear .2s;
    }

        button.mainnav span {
            display: block;
            line-height: 1em;
        }

        /*Closed*/
        button.mainnav span.navicon {
            margin-top: 16px;
            height: 10px;
            position: relative;
        }
            button.mainnav span.navicon span {
                display: block;
                width: 100%;
                margin: auto;
                height: 1px;
                background-color: var(--black);
                -moz-transition: background-color .2s ease-out .6s, width .2s ease-out;
                -o-transition: background-color .2s ease-out .6s, width .2s ease-out;
                -webkit-transition: background-color .2s ease-out .6s, width .2s ease-out;
                transition: background-color .2s ease-out .6s, width .2s ease-out;
            }
            button.mainnav span.navicon span:before {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background-color: var(--black);
                position: relative;
                top: -9px;
                -moz-transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
                -o-transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
                -webkit-transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
                transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
            }
            button.mainnav span.navicon span:after {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background-color: var(--black);
                position: relative;
                top: 8px;
                -moz-transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
                -o-transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
                -webkit-transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
                transition: transform .4s ease-out,top .2s cubic-bezier(1,0,0,.7) .4s;
            }

        /*Open*/
        button.mainnav.open span.navicon span {
            width: 75%;
            background-color: var(--white);
            -moz-transition: background-color .2s ease-out, width .2s ease-out .6s;
            -o-transition: background-color .2s ease-out, width .2s ease-out .6s;
            -webkit-transition: background-color .2s ease-out, width .2s ease-out .6s;
            transition: background-color .2s ease-out, width .2s ease-out .6s;
        }
            button.mainnav.open span.navicon span:before {
                transform: rotate(45deg);
                top: 0;
                -moz-transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
                -o-transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
                -webkit-transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
                transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
            }
            button.mainnav.open span.navicon span:after {
                transform: rotate(-45deg);
                top: -1px;
                -moz-transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
                -o-transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
                -webkit-transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
                transition: transform .4s ease-in-out .4s,top .2s cubic-bezier(1,0,0,.7) .2s;
            }

</style>