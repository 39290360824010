<script setup>
    import { defineProps, computed } from 'vue'
    import { linkTarget } from '@/composables/links.js'

    // Props
    const props = defineProps({
        className: {
            type: String,
            required: true
        },
        id: {
            type: Number,
            required: false
        },
        date: {
            type: Date,
            required: false
        },
        title: {
            type: String,
            required: false
        },
        subTitle: {
            type: String,
            required: false
        },
        details: {
            type: String,
            required: false
        },
        url: {
            type: String,
            required: false
        },
        content: {
            type: Object,
            required: false
        },
        files: {
            type: Array,
            required: false
        },
        type: {
            type: String,
            required: false
        }
    });

    const cssClass = computed(function() {
        return `${props.className.toLowerCase()}${props.url ? ' clickable' : ''}`;
    });

    const snippetImage = computed(function () {
        return props.files.find(f => f.type == "i");
    });

    const snippetContent = computed(function () {
        return `${props.subTitle ? "<h2>" + props.subTitle + "</h2>" : ""}${props.details}`;
    });

</script>

<template>
    <div v-if="snippetImage || snippetContent"
         :class="cssClass">

        <a v-if="url"
           :href="url"
           :target="linkTarget(url)">
            <picture>
                <img v-if="snippetImage" :src="snippetImage.filePath"
                     :alt="snippetImage.name" />
            </picture>
        </a>
        <picture v-else-if="snippetImage"
                 class="snippet-image">
            <img :src="snippetImage.filePath"
                 :alt="snippetImage.name" />
        </picture>
        <div class="snippet-content"
             v-html="snippetContent"></div>
    </div>
</template>

<style>
    div.clickable {
        cursor: pointer;
    }
</style>