<script setup>
    import { onBeforeMount, watch, ref } from 'vue'
    import { useRoute } from 'vue-router'
    import { useDestinationStore } from '@/stores/destination.js'
    import NavMenuItem from './NavMenuItem.vue'

    const route = useRoute();
    const destinationStore = useDestinationStore();
    const openItem = ref(null);

    onBeforeMount(() => {
        destinationStore.getRoutes("a"); // routes
    });

    const toggleOpen = function (value) {
        openItem.value = openItem.value == value ? null : value; 
    }

    // Close if route changes
    watch(route,
        () => {
            if (openItem.value) openItem.value = null;
        }
    );

</script>

<template>
    <nav role="navigation"
         class="main-nav" 
         v-if="destinationStore.routes && destinationStore.routes.length > 0">
        <ul class="root-menu">
            <nav-menu-item v-bind="route"
                           v-for="(route, index) in destinationStore.routes"
                           :key="index"
                           :open="route.entityId == openItem"
                           @toggleOpen="toggleOpen"></nav-menu-item>
        </ul>
    </nav>
</template>

<style>
    nav.main-nav {
        position: relative;
        z-index: -1;
        width: 100%;
        opacity: 0;
        -moz-transition: z-index .4s step-end, opacity .2s linear .2s;
        -o-transition: z-index .4s step-end, opacity .2s linear .2s;
        -webkit-transition: z-index .4s step-end, opacity .2s linear .2s;
        transition: z-index .4s step-end, opacity .2s linear .2s;
    }

        nav.main-nav ul.root-menu {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            padding: 0;
            width: 20em;
            list-style-type: none;
            background-color: var(--black);
        }

    /*Open*/
    body.nav-open nav.main-nav {
        opacity: 1;
        z-index: 10;
        -moz-transition: z-index .4s step-start, opacity .2s linear .2s;
        -o-transition: z-index .4s step-start, opacity .2s linear .2s;
        -webkit-transition: z-index .4s step-start, opacity .2s linear .2s;
        transition: z-index .4s step-start, opacity .2s linear .2s;
    }

    @media screen and (min-width: 600px) {

        nav.main-nav {
            max-width: 71.875em;
            margin-left: auto;
            margin-right: auto;
        }

        nav.main-nav ul.root-menu {
            background-color: var(--black-90);
            width: 100%;
        }

        nav.mainnav ul.root-menu, nav.mainnav.menu-open {
            margin-bottom: 0;
            height: 100%;
            max-height: 100%;
            overflow: visible;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -ms-flex-wrap: nowrap;
            -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
        }

            nav.mainnav.menu-open ul.root-menu {
                max-height: 100%;
            }
    }

    @media screen and (min-width: 900px) {
        nav.main-nav ul.root-menu {
            width: 50em;
            right: 0;
        }
    }

</style>