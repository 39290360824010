<script setup>
    import ContentSnippet from '@/components/content/ContentSnippet.vue'
    import LogoSvg from './LogoSvg.vue'

    const year = new Date().getFullYear();

</script>

<template>
    <footer class="site-footer">
        <logo-svg 
                  class="footer-logo-image">
        </logo-svg>

        <div class="footer-text">
            Understanding Sound is Learning How to Listen
        </div>

        <nav class="social-links flex">
            <a href="https://www.instagram.com/aaronbaudio/" target="_blank" class="instagram"><span>Instagram</span></a>
            <a href="https://www.facebook.com/AARONBAUDIOUK" target="_blank" class="facebook"><span>Facebook</span></a>
            <a href="https://www.youtube.com/channel/UChPj3b3Kw6jm26mZFOvVa6A" target="_blank" class="youtube"><span>YouTube</span></a>
            <a href="https://twitter.com/aaronbaudio" target="_blank" class="twitter"><span>Twitter</span></a>
        </nav>

        <nav class="copy-links flex">
            <router-link to="/cookie-policy" class="flex"><span>Cookie policy</span></router-link>
            <router-link to="/privacy-policy" class="flex"><span>Privacy policy</span></router-link>
            <span>&copy; {{ year }} AARON B</span>
        </nav>

        <p>AARON B is a trademark and brand of K&amp;A Audio Systems UK Ltd.<br />Company Number: 09095267</p>
    </footer>
</template>

<style>
    footer.site-footer {
        font-size: 0.625em;
        text-align: center;
        padding-top: 6.5em;
        padding-bottom: 2.25em;
        position: relative;
        z-index: 1;
    }

        footer.site-footer .footer-logo-image {
            margin-bottom: 4em;
            max-width: 70%;
            margin: 0 auto;
        }

        footer.site-footer .footer-text {
            display: block;
            text-align: center;
            text-transform: uppercase;
            font-size: 1rem;
            letter-spacing: 0.1em;
            padding: 0.875em;
            margin-bottom: 4em;
            box-sizing: border-box;
        }

        footer.site-footer .social-links {
            align-items: center;
            justify-content: center;
            margin: 2.5em 0 2.1875em 0;
        }
            footer.site-footer .social-links > * {
                flex-basis: auto;
                display: inline-block;
            }

            footer.site-footer .social-links a {
                margin: 0 1.25rem;
                position: relative;
            }

            footer.site-footer .social-links a span {
                display: none;
            }

                footer.site-footer .social-links a:before, footer.site-footer .social-links a:after {
                    content: "";
                    display: block;
                    width: 1.5625rem;
                    height: 1.5625rem;
                    -moz-filter: var(--svg-black);
                    -webkit-filter: var(--svg-black);
                    filter: var(--svg-black);
                }

                footer.site-footer .social-links a:before {
                    position: relative;
                    z-index: 2;
                    -moz-transition: opacity linear .2s;
                    -o-transition: opacity linear .2s;
                    -webkit-transition: opacity linear .2s;
                    transition: opacity linear .2s;
                }

                footer.site-footer .social-links a:after {
                    -moz-filter: var(--svg-bronze);
                    -webkit-filter: var(--svg-bronze);
                    filter: var(--svg-bronze);
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                }

                footer.site-footer .social-links a.instagram:before, footer.site-footer .social-links a.instagram:after {
                    content: url(../../assets/instagram.svg);
                }

                footer.site-footer .social-links a.facebook:before, footer.site-footer .social-links a.facebook:after {
                    content: url(../../assets/facebook.svg);
                }

                footer.site-footer .social-links a.youtube:before, footer.site-footer .social-links a.youtube:after {
                    width: 1.875rem;
                    height: 1.35rem;
                    content: url(../../assets/youtube.svg);
                }

                footer.site-footer .social-links a.twitter:before, footer.site-footer .social-links a.twitter:after {
                    content: url(../../assets/twitter.svg);
                }

                footer.site-footer .social-links a:hover:before {
                    opacity: 0;
                }

        footer.site-footer .copy-links {
            align-items: center;
            justify-content: center;
        }

            footer.site-footer .copy-links > * {
                flex-basis: auto;
                display: inline-block;
                margin: 0 0.5em;
            }

            footer.site-footer .copy-links a {
                color: var(--black);
                border-bottom: solid 1px var(--white);
                text-decoration: none;
                line-height: 1em;
                position: relative;
                -moz-transition: border-color linear .2s;
                -o-transition: border-color linear .2s;
                -webkit-transition: border-color linear .2s;
                transition: border-color linear .2s;
            }

                footer.site-footer .copy-links a:after {
                    content: "";
                    display: inline-block;
                    width: 1px;
                    height: 100%;
                    background-color: var(--black);
                    position: absolute;
                    top: 0;
                    right: -0.5em;
                }

                footer.site-footer .copy-links a:hover {
                    border-bottom-color: var(--black);
                }

    @media screen and (min-width: 900px) {
        footer.site-footer {
            padding-top: 9.5em;
            font-size: 0.75em;
        }

            footer.site-footer .footer-logo-image {
                max-width: 90%;
            }

            footer.site-footer .social-links a:before, footer.site-footer .social-links a:after {
                width: 2.1875rem;
                height: 2.1875rem;
            }
            footer.site-footer .social-links a.youtube:before, footer.site-footer .social-links a.youtube:after {
                width: 2.625rem;
                height: 1.875rem;
            }
    }
</style>