//const baseUrl = "https://api.webcharge.co";
//const baseUrl = "https://localhost:7029";
const baseUrl = process.env.VUE_APP_API_URL;

/* Fetch from server */
export async function fetchData(url = '', data = {}, method = 'POST', type = 'json') {
    // Set the header type if json
    const headers = {};
    if (type == 'json') headers['Content-Type'] = 'application/json';
    // Set the fetch options
    const options = {
        method: method, // *GET, POST, PUT, DELETE
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, *same-origin, omit
        headers,
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    };
    if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
        if (type === 'json') {
            options.body = JSON.stringify(data); // body data type must match "Content-Type" header
        }
        else {
            options.body = data; // Pass data as is for non-json
        }
    }

    // return data
    let body = null;
    let status = null;

    // Run the fetch
    const response = await fetch(`${baseUrl}${url}`, options);

    if (response.ok) {
        // If OK then set the body
        body = await response.json();
    }
    else if (response.status == 404) {
        // If 404 then redirect to 404 page
        window.location.href = "/404.html";
    }

    // Set the status
    status = response.status;

    // Return the result
    return { body, status }
}
