<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="481" height="49.249" viewBox="0 0 481 49.249">
        <defs>
            <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#91451d" />
                <stop offset="0.483" stop-color="#b55e3e" />
                <stop offset="1" stop-color="#c6663a" />
            </linearGradient>
        </defs>
        <g>
            <g>
                <path d="M22.437,0,0,49.249H10.452c4.9,0,8.571-2.015,10.335-5.677l4.3-9c3.168,6.819,6.74,14.571,6.74,14.571l.048.1H45.016ZM10.452,48.615H1.111L11.729,25.24c1.264-2.842,2.851-4.285,4.717-4.285a3.622,3.622,0,0,1,3.6,2.475l4.7,10.308-4.563,9.541c-1.657,3.441-5.114,5.336-9.735,5.336" transform="translate(0 0)" fill="url(#linear-gradient)" />
                <path d="M62.2,0,39.764,49.249H50.216c4.9,0,8.569-2.015,10.335-5.677l4.3-9c3.168,6.819,6.74,14.571,6.74,14.571l.048.1H84.78ZM50.216,48.615H40.875L51.493,25.24c1.263-2.842,2.849-4.285,4.715-4.285a3.622,3.622,0,0,1,3.6,2.475l4.7,10.308-4.563,9.541c-1.657,3.441-5.114,5.336-9.735,5.336" transform="translate(6.787 0)" fill="url(#linear-gradient)" />
                <path d="M102.249,24.8A12.407,12.407,0,0,0,99.141.392H78.126V37.057h.7v-.179A11.784,11.784,0,0,1,89.277,25.22l10.958,23.858.047.1h13.144Zm-.288-.674L94.5,7.825a5.063,5.063,0,0,1-.337-1.749A4.985,4.985,0,0,1,99.141,1.1a11.691,11.691,0,0,1,2.82,23.031m-23.13,8.654V2.3L88.982,24.6a12.26,12.26,0,0,0-10.151,8.18" transform="translate(13.335 0.067)" fill="url(#linear-gradient)" />
                <path d="M194.72.392V47.4L171.711.493l-.049-.1h-13.6v48.79h4.36c3.4,0,4.161-1.4,5.32-4.079l5.7-12.893,8.333,16.873.05.1h13.6V.392ZM167.068,44.821c-.99,2.245-1.646,3.727-4.646,3.727h-3.655V2.173l14.33,29.2Z" transform="translate(26.978 0.067)" fill="url(#linear-gradient)" />
                <path d="M245.57,21.381A12.4,12.4,0,0,0,236.653.392H217.38v48.79h19.343a15.312,15.312,0,0,0,8.847-27.8m-8.847,27.167h-7.348V6.076A4.985,4.985,0,0,1,234.354,1.1h2.3a11.682,11.682,0,0,1,11.669,11.669,11.542,11.542,0,0,1-3.5,8.374l-.054.053v.448l.078.053a14.651,14.651,0,0,1-8.123,26.854" transform="translate(37.103 0.067)" fill="url(#linear-gradient)" />
                <path d="M135.059.392a24.4,24.4,0,1,0,24.362,24.43A24.422,24.422,0,0,0,135.059.392M122.252,4.866a23.7,23.7,0,1,1,12.807,43.68,23.576,23.576,0,0,1-12.807-3.77Z" transform="translate(18.895 0.067)" fill="url(#linear-gradient)" />
            </g>
            <text data-name="Audio Systems" transform="translate(393 31)"><tspan x="-87.09" y="0">Audio Systems</tspan></text>
        </g>
        <title>Aaron B Audio Systems logo</title>
    </svg>
</template>

<style scoped>
    svg {
        pointer-events: none;
    }
    text {
        fill: var(--black);
        font-size: 20px;
        font-family: var(--font-text);
        font-weight: 300;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }
</style>