// Induction Module
import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { fetchData } from '@/composables/fetch.js'

export const useDestinationStore = defineStore('destination', () => {
    // State
    let content = ref(null);
    let status = ref(null);
    const routes = ref(null);

    // Getters
    const getters = {
        destinationMeta: computed(() => {
            if (!content.value) return null;

            const meta = {
                title: content.value.metaTitle,
                description: content.value.metaDescription,

                socialTitle: content.value.socialTitle,
                socialDescription: content.value.socialDescription,

                siteName: content.value.site.name,
                siteUrl: content.value.site.url,
                siteSsl: content.value.site.ssl,
                siteSocialImage: content.value.site.defaultSMImage,
                siteSocialImageAlt: content.value.site.defaultSMImageAlt,
                siteLocale: content.value.site.locale,
                siteFBName: content.value.site.facebookName,
                siteTwitterName: content.value.site.twitterName,
                siteTwitterCardType: content.value.site.twitterCardType,
                siteDefaultBannerImage: content.value.site.defaultBannerImage
            }

            // Social image
            if (content.value.socialImage) {
                meta.socialImage = content.value.socialImage;
                if (content.value.socialImageAlt) meta.socialImageAlt = content.value.socialImageAlt
            }
            else if (content.value.files.length > 0) {
                meta.socialImage = content.value.files[0].filePath;
                meta.socialImageAlt = content.value.files[0].name;
            }
            return meta;

        }),
        destinationContent: computed(() => {
            return content.value ? content.value : undefined;
        }),
        destinationTemplate: computed(() => {
            return content.value ? content.value.template : undefined;
        })
    };

    // Actions
    const setContent = function (cData) {
        // Set data
        content.value = cData;
    };
    const setStatus = function (s) {
        // Set data
        status.value = s;
    };
    const setRoutes = function (rData) {
        // Set data
        routes.value = rData;
    };
    const actions = {
        getContent: async function (route) {
            const { body, status } = await fetchData(`/destination?route=${encodeURIComponent(route)}`, null, 'GET')
                .then(function (response) {
                    return response;
                });
            setContent(body);
            setStatus(status);
        },
        getRoutes: async function (display) {
            const content = await fetchData(`/destinationRoutes${display ? "?display=" + display : ""}`, null, 'GET')
                .then(function (response) {
                    return response.body;
                });
            setRoutes(content);
        }
    };

    function $reset() {
        content.value = null
    }

    // Return the state, getters and actions
    return {
        content,
        status,
        routes,
        ...getters,
        ...actions,
        $reset
    }
});